import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Navigation from './Navigation'
import './layout.scss'
import Footer from './Footer'
import { Page } from '../model'

interface Props {
  children?: React.ReactNode
  locale: string
  page: Page
}

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

export default function Layout(props: Props) {
  const { locale, children, page } = props
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      et: contentfulLayout {
        ...layoutFields
      }
      en: contentfulLayout(node_locale: { eq: "en" }) {
        ...layoutFields
      }
    }

    fragment layoutFields on ContentfulLayout {
      node_locale
      navLinks {
        id
        title
        url
      }
      links {
        title
        links {
          id
          title
          url
        }
      }
    }
  `)

  const layout = data[locale]

  const title = page.metaTitle || `${page.slug} | Teras Beach Tallinna`
  const description =
    page.metaDescription ||
    'Teras Beach Tallinna. White sand, +26, sauna. Beach volley, Beach Tennis year around.'
  return (
    <Container>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: description,
          },
          {
            name: 'keywords',
            content: 'beach, sand, bar, sauna, party',
          },
        ]}
      >
        <html lang="et" />
      </Helmet>
      <Navigation items={layout.links} locale={locale} slug={page.slug} />
      <div>{children}</div>
      <Footer />
    </Container>
  )
}
