export default {
  Additional_information: 'Additional information',
  All_courts: 'All courts',
  Ask: 'Ask',
  Bachelor_party: 'Bachelor party',
  Beach_tennis: 'Beach Tennis',
  Beach_volley: 'Beach Volley',
  Birthday: 'Birthday',
  C: 'C',
  Cancel: 'Cancel',
  Click_to_reserve: 'Click to reserve',
  Close: 'Close',
  Continue: 'Continue',
  Contact: 'Contact',
  court: 'court',
  Courts: 'Courts',
  Email: 'Email',
  Email_reservation: 'Send email',
  Facebook_email: 'Use your Facebook/Google account,',
  Facebook_Login: 'Continue with Facebook',
  Foot_volley: 'Foot Volley',
  Free: 'Free',
  FirstName: 'Firstname',
  Google_Login: 'Sign in with Google',
  klo: '',
  LastName: 'Lastname',
  load_more: 'Load more',
  More_info: 'More info',
  Name: 'Name',
  or_phone: 'email or call us ',
  or: 'or',
  Over:'Over',
  People_count: 'People count',
  Person_count: 'Person count',
  Phone: 'Phone',
  Playing: 'Playing',
  Purpose: 'Purpose',
  PrivacyPolicy: 'Privacy policy',
  PrivacyLink: '/privacy-policy',
  Reservation_confirmation_sent_to: 'Reservation confirmation sent to:',
  Reservation_link: 'Reservation link (do not remove)',
  Reservation_Link_Intro:
    'Give your email address. You will receive email that contains reservation link.',
  Reservation_Link_Sent: 'Reservation link sent',
  Reservation_Link_Continue: 'Continue by clicking reservation email.',
  Reservation: 'Reservation',
  Reserve_now: 'Booking',
  Reserve: 'Reserve',
  Reserved: 'Reserved',
  Sorry_reserved: 'Sorry. Somebody just reserved this time. Check other time.',
  Tell_us: 'Tell us if you would like to stay longer for sauna or partying',
  Thank_you: 'Thank you!',
  Today: 'Today',
  Under: 'Under',
}
