import en from './locales/en'
import ee from './locales/ee'
import fi from './locales/fi'
import config from './config'

const locales = { en, ee, fi }

const T = key => {
  const translations = locales[config.locale]
  if (!translations || translations[key] === '') {
    return ''
  }
  return translations[key] || key
}

export default T
