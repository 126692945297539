export default {
  Additional_information: 'Lisainformatsioon',
  All_courts: 'Kõik väljakud',
  Ask: 'Küsi',
  Bachelor_party: 'Pidu',
  Beach_tennis: 'Rannatennise',
  Beach_volley: 'Rannavõrkpalli',
  Birthday: 'Sünnipäev',
  C: 'V',
  Cancel: 'Tühista',
  Click_to_reserve: 'Broneeri aeg',
  Close: 'Sulge',
  Continue: 'Jätkake',
  Contact: 'Kontakt',
  court: 'väljak',
  Courts: 'Väljakud',
  Email: 'Email',
  Email_reservation: 'E-post',
  Facebook_email: 'FB/Google konto kaudu,',
  Facebook_Login: 'Facebook Login',
  Foot_volley: 'Foot Volley',
  Free: 'Vaba',
  FirstName: 'Eesnimi',
  Google_Login: 'Google Login',
  klo: 'kl',
  LastName: 'Perekonnanimi',
  load_more: 'Ava rohkem',
  More_info: 'Lisainfo',
  Name: 'Nimi',
  or_phone: 'e-posti teel või telefonil',
  or: 'või',
  Over: 'Üle',
  People_count: 'Osalejate arv',
  Person_count: 'Mängijate arv',
  Phone: 'Tel',
  Playing: 'Pallimäng',
  Price: 'Hind',
  Purpose: 'Eesmärk',
  PrivacyPolicy: 'Privatsus',
  PrivacyLink: '/privacy-policy',
  Reservation_confirmation_sent_to:
    'Teie tellimuse kinnitus saadeti e-postile:',
  Reservation_link:
    'Broneeringu link (palun link alles jätta ja kirjaga koos saata)',
  Reservation_Link_Intro:
    'Palun sisestage oma e-posti aadress. Saadame teile e-kirja, milles on broneerimiseks vajalik link.',
  Reservation_Link_Sent: 'Broneeringu link on saadetud',
  Reservation_Link_Continue: 'Jätkamiseks vajutake e-mailis olevale lingile.',
  Reservation: 'Broneering',
  Reserve_now: 'Väljakuaja\tbroneering',
  Reserve: 'Broneeri',
  Reserved: 'Broneeritud',
  Sorry_reserved:
    'Vabandame! See aeg broneeriti juba kellegi teise poolt. Palun vali mõni teine vaba aeg.',
  Tell_us:
    'Palun anna teada, kui soovid jääda pikemalt saunatama ja/või meie juures õhtut veetma.',
  Thank_you: 'Aitäh!',
  Today: 'Täna',
  Under: 'Alla',
}
