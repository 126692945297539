export default {
  Additional_information: 'Lisätietoja',
  All_courts: 'Kaikki kentät',
  Ask: 'Kysy',
  Bachelor_party: 'Polttarit',
  Beach_tennis: 'Pelaamme Beach Tennistä',
  Beach_volley: 'Pelamme Beach Volleytä',
  Birthday: 'Synttärit',
  C: 'K',
  Cancel: 'Peruuta',
  Click_to_reserve: 'Klikkaa varataksesi',
  Close: 'Sulje',
  Continue: 'Jatka',
  Contact: 'Ota yhteyttä',
  court: 'kenttä',
  Courts: 'Kentät',
  Email: 'Sähköposti',
  Email_reservation: 'Lähetä sähköposti',
  Facebook_email: 'Varaa Facebook/Google tunnuksellasi',
  Facebook_Login: 'Kirjaudu Facebook',
  Foot_volley: 'Pelaamme Foot Volleytä',
  Free: 'Vapaa',
  FirstName: 'Etunimi',
  Google_Login: 'Kirjaudu Google',
  klo: '',
  LastName: 'Sukunimi',
  load_more: 'Hae lisää',
  More_info: 'Lisätietoa',
  Name: 'Nimi',
  or_phone: 'sähköpostilla tai soita meille ',
  or: 'tai',
  Over:'Yli',
  People_count: 'Henkilömäärä',
  Person_count: 'Henkilömäärä',
  Phone: 'Puhelin',
  Playing: 'Pelaamaan',
  Purpose: 'Tarkoitus',
  PrivacyPolicy: 'Tietoturvaseloste',
  PrivacyLink: '/tietosuojaseloste',
  Reservation_confirmation_sent_to: 'Varausvahvistus lähetetty:',
  Reservation_link: 'Varus linkki (älä poista)',
  Reservation_Link_Intro:
    'Anna sähköpostisi. Saat linkin jolla pääset jatkamaan varausta.',
  Reservation_Link_Sent: 'Varauslinkki lähetetty',
  Reservation_Link_Continue: 'Jatka varausta sähköpostin linkillä.',
  Reservation: 'Varaukset',
  Reserve_now: 'Varaa',
  Reserve: 'Varaa',
  Reserved: 'Varattu',
  Sorry_reserved:
    'Valitettavasti joku muu ehti juuri varata vuoron. Yritä toista ajankohtaa.',
  Tell_us: 'Toivomuksia tai kysymyksiä',
  Thank_you: 'Varaus vahvistettu!',
  Today: 'Tänään',
  Under: 'Alle',
}
