const Colors = {
  primary: '#f6e824',
  border: 'hsl(209,21%,88%)',
  white: '#fff',
  greyBackGround: '#F7F6F5',
  green: '#52c41a',
  gray50: 'rgba(71,55,41,0.5)',
  inactiveTint: '#5e6977',
  black: '#000',
  text: '#333',
}

export default Colors
