import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import Colors from '../calendar/util/Colors'
import T from '../calendar/T'

const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  font-size: 0.8rem;
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  a {
    color: #fff;
  }
  a:visited {
    color: #fff;
  }
  a:hover {
    color: #fff;
  }
`
const FooterContent = styled.div`
  border-top: 4px solid ${Colors.primary};
  margin-top: 2rem;
  padding-top: 2rem;
  color: #dfdfdf;
`
const FooterSocialLinks = styled.span`
  display: flex;
  font-size: 1.4rem;
  line-height: 2.8rem;
  @media (min-width: 1024px) {
    font-size: 1.8rem;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const LinkPrimary = styled(Link)`
  color: ${Colors.primary} !important;
  :visited {
    color: ${Colors.primary};
  }
`

const FooterSocialLink = styled.a`
  border-bottom: 4px solid #fff;
  color: #fff;

  padding-right: 2rem;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding-bottom: 1.8rem;
  }

  @media (max-width: 767px) {
    border-bottom: none;
  }
`
const FooterInsta = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`
const FooterInstaImg = styled.img`
  @media (max-width: 1023px) {
    max-width: 100px;
    max-height: 100px;
  }

  margin-right: 5px;
  margin-bottom: 5px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <div className="row">
        <div className="col-md-4 col-lg-3 offset-lg-1">
          <img
            width="176"
            height="40"
            className="footer__logo"
            src="https://images.ctfassets.net/6ag9zos91onm/3W2odpVWcJUKDwqGegpBKz/866d2b18fb17349c1ccc14274f8a1570/teras-logo.svg"
            alt="Teras Beach"
          />
          <FooterContent>
            <ul className="list-unstyled">
              <li>
                <Link to="https://www.biitsi.fi/pasila">Biitsi Pasila</Link>
              </li>
              <li>
                <Link to="https://www.biitsi.fi/salmisaari">
                  Biitsi Salmisaari
                </Link>
              </li>
              <li>
                <Link to="https://www.biitsi.fi/tallinna">
                  Teras Beach Tallinna
                </Link>
              </li>
              <li className="mb-3">
                <Link to="https://www.biitsi.fi/vantaa">Biitsi Vantaa</Link>
              </li>

              <li className="mb-3">
                <LinkPrimary to="/contact">{T('Contact')}</LinkPrimary>
              </li>

              <li>
                <a href={`tel:+37253070001`}>+372 5307 0001</a>
              </li>
              <li className="mb-3">
                <a href={`mailto:info@terasbeach.ee`}>info@terasbeach.ee</a>
              </li>

              <li>
                <Link to="/privacy-policy">Privaatsus</Link>
              </li>
              <div className="my-3">
                <LinkPrimary to="/app">
                  Biitsi App for Teras - Reserve, invite, play
                </LinkPrimary>
              </div>
              <a
                href="https://apps.apple.com/us/app/biitsi/id1436428978"
                target="_blank"
              >
                <img
                  width="140px"
                  height="41px"
                  className="my-3 mr-3"
                  src="https://images.ctfassets.net/pnyokv5ndhzc/22hTgOQImln0ty5o3TttAC/3881c841014dc3b7cece530a58e30152/badgeappstore.png"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=fi.biitsi.app"
                target="_blank"
              >
                <img
                  width="140px"
                  height="41px"
                  className="my-3"
                  src="https://images.ctfassets.net/pnyokv5ndhzc/7nIk20f1bBfR0Uw8agWlrg/8af7f1e29c11fb316be5449b7f8db111/badgegoogleplay.png"
                />
              </a>
            </ul>
          </FooterContent>
        </div>
        <div className="col-md-8 col-lg-7 offset-lg-1">
          <FooterSocialLinks>
            <FooterSocialLink
              href="http://facebook.com/terasbeach"
              target="_blank"
              rel="noopener noreferrer"
              title="FACEBOOK"
            >
              FACEBOOK
            </FooterSocialLink>
            <FooterSocialLink
              href="https://www.instagram.com/terasbeachtallinn/"
              rel="noopener noreferrer"
              target="_blank"
            >
              INSTAGRAM{' '}
            </FooterSocialLink>
          </FooterSocialLinks>
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer
