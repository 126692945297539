import { CalendarProps } from './Calendar'

const config: CalendarProps = {
  locale: 'ee',
  facebookAppId: '',
  googleClientId: '',
  introText: '',
  bookingText: '',
  apiUrl: '',
}
export default config
