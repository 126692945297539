import React, { useState } from 'react'
import './Navigation.scss'
import classnames from 'classnames'
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import { Link } from 'gatsby'
import T from '../calendar/T'
import config from '../calendar/config'
import styled from 'styled-components'

interface Item {
  url: string
  title: string
}

interface TopNavItemProps {
  slug: string
  item: Item
}

const AppLink = styled.span`
  a:hover {
    color: #333 !important;
  }
`

const TopNavItem: React.SFC<TopNavItemProps> = ({ slug, item }) => {
  return (
    <NavItem
      className={classnames({
        active: slug && item.url === `/${slug}`,
      })}
      key={item.url}
    >
      <Link to={item.url} className="nav-link">
        {item.title}
      </Link>
    </NavItem>
  )
}
interface Cta {
  title: string
  url: string
}
interface Props {
  items: Cta[]
  locale: string
  slug: string
}

export default function Navigation(props: Props) {
  const [isOpen, setOpen] = useState<boolean>(false)
  const { locale, slug, items } = props

  const toggle = () => {
    setOpen(!isOpen)
  }

  const frontLink = locale === 'et' ? '/' : `/${locale}`
  const localePrefix = locale === 'et' ? '' : `/${locale}/`
  const collapseStyle = isOpen ? {} : { display: 'none' }
  return (
    <Navbar dark expand="md">
      <Link to={frontLink} className="navbar-brand">
        <img
          width="176"
          height="40"
          src="https://images.ctfassets.net/6ag9zos91onm/3W2odpVWcJUKDwqGegpBKz/866d2b18fb17349c1ccc14274f8a1570/teras-logo.svg"
          alt="Teras Beach"
        />
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar style={collapseStyle}>
        <Nav className="mx-auto" navbar>
          {items.map((item) => (
            <UncontrolledDropdown nav inNavbar key={item.url}>
              <DropdownToggle nav caret>
                {item.title}
              </DropdownToggle>
              <DropdownMenu right>
                {item.links.map((link) => (
                  <Link
                    key={link.url}
                    to={localePrefix + link.url}
                    className="dropdown-item"
                  >
                    {link.title}
                  </Link>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          ))}
        </Nav>

        <span className="navbar-text">
          <AppLink>
            <Link
              to="/app"
              className="btn btn-outline-primary mr-3 d-md-none d-lg-inline-block"
            >
              App
            </Link>
          </AppLink>
          {slug != 'reserve' && (
            <Link
              to={locale === 'en' ? `/en/reserve` : `/reserve`}
              className="btn btn-primary mr-3"
              style={{ color: '#333' }}
            >
              {T('Reserve')}
            </Link>
          )}
          {locale === 'en' && (
            <Link
              style={{ color: '#fff' }}
              onClick={() => {
                config.locale = 'ee'
              }}
              to={
                typeof window !== 'undefined'
                  ? window.location.pathname.substr(4)
                  : ''
              }
            >
              EST
            </Link>
          )}
          {locale !== 'en' && (
            <Link
              style={{ color: '#fff' }}
              onClick={() => {
                config.locale = 'en'
              }}
              to={
                typeof window !== 'undefined'
                  ? '/en/' + window.location.pathname
                  : '/en'
              }
            >
              ENG
            </Link>
          )}
        </span>
      </Collapse>
    </Navbar>
  )
}
